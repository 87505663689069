import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { theme } from "../../design/Themes"
import { Form, Field } from 'react-final-form';
import { Translate } from 'react-localize-redux';
import { isMobileOnly } from 'react-device-detect';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';
import { UPDATE_PHONE_2FA_ENDPOINT, VERIFY_PHONE_2FA_ENPOINT, CHECK_STATUS_2FA_ENPOINT,RESEND_CODE_2FA_ENPOINT } from "../../services/endpoints";
import { toastDanger, toastSuccess } from "../../features/ToastMessage";
import NoticeDanger from "../Notices/NoticeDanger";
import { validatePhoneNumber, validateMkPhoneNumber } from "../../common/validationRules";
import { getToken } from "../../services/authService";




import InputField from '../InputField';
import PrimaryButton from '../Buttons/PrimaryButton';
import Panel from '../Panel';
import FormGroup from '../FormGroup';
import { findLastIndex } from "lodash";

const StyledForm = styled.form`
  padding: 50px;
  background-color: ${theme.white};
  border: 1px solid ${theme.neutral3};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

`;
const LinkDiv=styled.div`
margin-top:5px;
displlay:flex;
justify-content:center;
align-items:center;`

const TwoStepAuthentication = ({ }) => {
  const [showEnableForm, setShowEnableForm] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [isTwoStepAuthEnabled, setIsTwoStepAuthEnabled] = useState();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showNumbersForm,setShowNumbersForm]=useState(false);


  useEffect(() => {
    axios.get(CHECK_STATUS_2FA_ENPOINT)
      .then(response => {
        if (response.data && !response.data.error) {
          setIsTwoStepAuthEnabled(response.data.data['2fa']);
          setPhoneNumber(response.data.data.phone_number);
        }
      })
      .catch(error => {
        console.error('Error fetching 2FA status:', error);
      });
  }, []);

  const maskPhoneNumber = (phoneNumber) => {
    if (phoneNumber && phoneNumber.length > 6) {
      return `${phoneNumber.slice(0, 4)}${'*'.repeat(phoneNumber.length - 6)}${phoneNumber.slice(-2)}`;
    }
    return phoneNumber;
  }

  const combinedPhoneNumberValidation = (phoneNumber) => {
    const mkPhoneNumberPattern = /^(070|078|072|076|071|077|079|075|\+389|389)/;
    if (mkPhoneNumberPattern.test(phoneNumber)) {
      return validateMkPhoneNumber(phoneNumber);
    } else {
      return validatePhoneNumber(phoneNumber);
    }
  };

  const handleResendCode = async (event) => {
    event.preventDefault();
   
      const response = getToken();
      const result = await resendCode(response);
    
  };

  const resendCode = async (token) => {

    try {
      const response = await axios.get(RESEND_CODE_2FA_ENPOINT, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.error === true) {
        toastDanger(<Translate id="resend-code-error" />);
      } else {
        toastSuccess(<Translate id="resend-code-success" />);
      }

      return response.data;
    } catch (error) {
      throw error;
    }

  };


  const sendCode = async (values) => {
    try {
      const response = await axios.post(UPDATE_PHONE_2FA_ENDPOINT, {
        phone: values.phoneNumber,
      });
      if (!response.data.error) {
        setIsCodeSent(true);
        setPhoneNumber(values.phoneNumber)
      }
    } catch (error) {
      console.error('Error updating phone number:', error);
      setSubmitError(error.message || 'An error occurred while sending the code.');
    }
  };

  const sendCode2 = async (values) => {
    try {

      const response = await axios.post(UPDATE_PHONE_2FA_ENDPOINT, {
        phone: values.newPhoneNumber,
      });
      if (!response.data.error) {
        setIsCodeSent(true);
        setPhoneNumber(values.newPhoneNumber)

      }
    } catch (error) {
      console.error('Error updating phone number:', error);
      setSubmitError(error.message || 'An error occurred while sending the code.');
    }
  };


  const verifyCode = async (values) => {
    try {
      const response = await axios.post(VERIFY_PHONE_2FA_ENPOINT, {
        code: values.verificationCode,
      });


      if (!response.data.error && isTwoStepAuthEnabled) {
        toastSuccess(<Translate id="success-two-factor-number" />);
      } else if (!response.data.error && !isTwoStepAuthEnabled){
        toastSuccess(<Translate id="success-two-factor-enabled" />);
      }else if(response.data.error && isTwoStepAuthEnabled){
        toastDanger(<Translate id="error-two-factor-number" />);
      }else if(response.data.error && !isTwoStepAuthEnabled){
        toastDanger(<Translate id=" error-two-factor-code" />);
       
      }
    } catch (error) {

      setSubmitError(error.message || 'An error occurred during verification.');
    }
  };


  return (
    <Translate>
      {({ translate }) => (
        !isTwoStepAuthEnabled ? (
          !showEnableForm ? (
            <Panel
              title={translate("my-account.security.two-step-authentication")}
              description={translate("my-account.security.two-step-auth-description")}
            >
              <FormGroup className="text-right">
                <PrimaryButton onClick={() => setShowEnableForm(true)}>
                  {translate("my-account.security.enable-two-step-auth-action")}
                </PrimaryButton>
              </FormGroup>
            </Panel>
          ) : (
            <Panel
              title={translate(isCodeSent ? "twoFactorAuthentication.verifyTitle" : "twoFactorAuthentication.title")}
              description={translate(isCodeSent ? "twoFactorAuthentication.descriptionPhone" : "twoFactorAuthentication.description", { phone: maskPhoneNumber(phoneNumber) })}
            >
              <Form
                onSubmit={isCodeSent ? verifyCode : sendCode}
                render={({ handleSubmit, submitting }) => (
                  <form onSubmit={handleSubmit} isMobile={isMobileOnly}>
                    {!isCodeSent ? (
                      <>
                        <Field
                          name="phoneNumber"
                          component={InputField}
                          placeholder={translate("twoFactorAuthentication.phoneNumberPlaceholder")}
                          label={translate("twoFactorAuthentication.phoneNumber")}
                          validate={(value) => combinedPhoneNumberValidation(value)}
                          autoComplete="tel"
                          type="tel"

                        />
                        <GoogleReCaptcha onVerify={(token) => { /* handle reCAPTCHA token */ }} />
                        <FormGroup className="text-right">
                          <PrimaryButton type="submit" disabled={submitting} submitting={submitting}>
                            {translate("twoFactorAuthentication.sendCode")}
                          </PrimaryButton>
                        </FormGroup>
                      </>
                    ) : (
                      <>
                        <Field
                          name="verificationCode"
                          component={InputField}
                          placeholder={translate("twoFactorAuthentication.verificationCodePlaceholder")}
                          label={translate("twoFactorAuthentication.verificationCode")}
                        />
                             <LinkDiv>
                        <a href="#" onClick={handleResendCode} className="alternative-link">
                            {translate('twoFactorAuthentication.resendCode')}
                          </a>
                        </LinkDiv>
                        <FormGroup className="text-right">
                          <PrimaryButton type="submit" disabled={submitting} submitting={submitting}>
                            {translate("twoFactorAuthentication.verifyButton")}
                          </PrimaryButton>
                         
                        </FormGroup>
                   
                      
                      </>
                    )}
                    {submitError && <NoticeDanger>{submitError}</NoticeDanger>}
                  </form>
                )}
              />
            </Panel>
          )
        ) : (

          <Panel
            title={translate(isCodeSent ? "twoFactorAuthentication.verifyTitle" : "twoFactorAuthentication.verifyTitle")}
            description={translate(
              isCodeSent ? "twoFactorAuthentication.descriptionPhone" : "my-account.security.two-step-auth-description",
              { phone: maskPhoneNumber(phoneNumber) }
            )}
          >
            <Form
              onSubmit={isCodeSent ? verifyCode : sendCode2}
              render={({ handleSubmit, submitting, invalid }) => (
                <form
                  onSubmit={handleSubmit}
                  isMobile={isMobileOnly}
                >
                  {!isCodeSent || showForm ? (
                    <>
                      <Field
                        name="currentPhoneNumber"
                        label={translate("my-account.security.current-phone-number")}
                        placeholder={phoneNumber}
                        type="number"
                        component={InputField}
                        disabled
                      />
                      <Field
                        name="newPhoneNumber"
                        label={translate("my-account.security.new-phone-number")}
                        placeholder={translate("my-account.security.new-phone-number-placeholder")}
                        autoComplete="tel"
                        type="tel"
                        component={InputField}
                        required
                        validate={(value) => combinedPhoneNumberValidation(value)}
                      />

                      <FormGroup className="text-right">
                        <PrimaryButton
                          type="submit"
                          disabled={submitting || invalid}
                          submitting={submitting}
                          className="action-button"
                        >
                          {translate("my-account.security.change-phone-number-action")}
                        </PrimaryButton>

                      </FormGroup>
                    </>
                  ) : (
                    <>

                      <Field
                        name="verificationCode"
                        component={InputField}
                        placeholder={translate("twoFactorAuthentication.verificationCodePlaceholder")}
                        label={translate("twoFactorAuthentication.verificationCode")}
                      />
                        <a href="#" onClick={handleResendCode} className="alternative-link">
                            {translate('twoFactorAuthentication.resendCode')}
                          </a>
                      <FormGroup className="text-right">
                        <PrimaryButton type="submit" disabled={submitting} submitting={submitting}>
                          {translate("twoFactorAuthentication.verifyButton")}
                        </PrimaryButton>
                      </FormGroup>
                    </>
                  )}
                </form>
              )}
            />
          </Panel>
        )
      )}
    </Translate>
  );
};

export default TwoStepAuthentication;
