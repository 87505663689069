// if you are confused why is there %(argument)s in some constants
// look up https://github.com/alexei/sprintf.js#readme

const BASE_API_ENDPOINT = process.env.REACT_APP_MKHOST_ENDPOINT
  ? process.env.REACT_APP_MKHOST_ENDPOINT
  : "http://127.0.0.1:10002/public/v1";
// const BASE_API_ENDPOINT = "http://127.0.0.1:10002/public/v1";
//
//

export const ROOT_DOMAIN_NO_HTTPS = process.env.REACT_APP_MKHOST_ROOT_DOMAIN
  ? process.env.REACT_APP_MKHOST_ROOT_DOMAIN
  : "mk-host.mk";

export const AUTH_API_ENDPOINT = `${BASE_API_ENDPOINT}/login`;
export const FORGOT_PASSWORD_API_ENDPOINT = `${BASE_API_ENDPOINT}/forgot-password`;
export const RESET_PASSWORD_API_ENDPOINT = `${BASE_API_ENDPOINT}/reset-password`;
export const VALIDATE_RESET_TOKEN_API_ENDPOINT = `${BASE_API_ENDPOINT}/reset-password-validate-token`;

export const AUTHORIZE_2FA_ENDPOINT = `${BASE_API_ENDPOINT}/2fa/authorize`;
export const UPDATE_PHONE_2FA_ENDPOINT = `${BASE_API_ENDPOINT}/customer/2fa/update-phone`;
export const VERIFY_PHONE_2FA_ENPOINT = `${BASE_API_ENDPOINT}/customer/2fa/verify-phone`;
export const CHECK_STATUS_2FA_ENPOINT = `${BASE_API_ENDPOINT}/customer/2fa/status`;
export const RESEND_CODE_2FA_ENPOINT = `${BASE_API_ENDPOINT}/customer/2fa/resend-code`;
export const RESEND_CODE_2FA_ENPOINT_LOGIN = `${BASE_API_ENDPOINT}/2fa/resend-code`;


export const CART_API_ENDPOINT = `${BASE_API_ENDPOINT}/cart`;
export const CART_ADD_ITEM_API_ENDPOINT = `${CART_API_ENDPOINT}/add-item`;
export const CART_REMOVE_ITEM_API_ENDPOINT = `${CART_API_ENDPOINT}/remove-item`;
export const CART_UPDATE_ITEM_API_ENDPOINT = `${CART_API_ENDPOINT}/update-item`;
export const CART_APPLY_PROMOTION_CODE_API_ENDPOINT = `${CART_API_ENDPOINT}/apply-promotion-code`;
export const CART_CLEAR_PROMOTION_CODE_API_ENDPOINT = `${CART_API_ENDPOINT}/clear-promotion-code`;

//change this
export const CART_APPLY_REFERRER_CODE_API_ENDPOINT = `${CART_API_ENDPOINT}/attach-referrer`;
export const CART_CLEAR_REFERRER_CODE_API_ENDPOINT = `${CART_API_ENDPOINT}/clear-referrer`;

export const CART_ATTACH_SERVICE_TO_PRODUCT_API_ENDPOINT = `${CART_API_ENDPOINT}/attach-service-to-order-item`;
export const CART_DETACH_SERVICE_TO_PRODUCT_API_ENDPOINT = `${CART_API_ENDPOINT}/detach-service-from-order-item`;
export const CART_ATTACH_ASSISTANT_TO_ORDER_ITEM = `${CART_API_ENDPOINT}/attach-domain-assistant-to-order-item`;
export const CART_UPDATE_ASSISTANT_TO_ORDER_ITEM = `${CART_API_ENDPOINT}/update-domain-assistant-to-order-item`;
export const CART_CLEAR_API_ENDPOINT = `${CART_API_ENDPOINT}/empty`;
export const CART_CHECKOUT_API_ENDPOINT = `${CART_API_ENDPOINT}/checkout`;

export const PRODUCTS_API_ENDPOINT = `${BASE_API_ENDPOINT}/shop/catalog/hosting`;
export const PRODUCTS_LISTING_API_ENDPOINT = `${PRODUCTS_API_ENDPOINT}/listing`;
export const PRODUCTS_LISTING_BY_GROUP_API_ENDPOINT = `${PRODUCTS_API_ENDPOINT}/listing/by-group`;
export const PRODUCTS_UPGRADES_LISTING = `${BASE_API_ENDPOINT}/hostings/%(catalog_id)s/upgrades`;
export const PRODUCTS_UPGRADE_PRODUCT = `${BASE_API_ENDPOINT}/hostings/upgrade`;
export const PRODUCTS_PRODUCT_REQUEST_RENEWAL = `${BASE_API_ENDPOINT}/hostings/%(id)s/request-renewal`;
export const PRODUCTS_PRODUCT_CANCEL = `${BASE_API_ENDPOINT}/hostings/%(id)s/request-cancellation`;

export const SERVERS_UPGRADES_LISTING = `${BASE_API_ENDPOINT}/servers/%(catalog_id)s/upgrades`;
export const SERVERS_UPGRADE_SERVER = `${BASE_API_ENDPOINT}/servers/upgrade`;
export const SERVERS_SERVER_REQUEST_RENEWAL = `${BASE_API_ENDPOINT}/servers/%(id)s/request-renewal`;
export const SERVERS_SERVER_CANCEL = `${BASE_API_ENDPOINT}/servers/%(id)s/request-cancellation`;

export const DEDICATED_SERVERS_API_ENDPOINT = `${BASE_API_ENDPOINT}/shop/catalog/dedicated-server`;
export const DEDICATED_SERVERS_LISTING_API_ENDPOINT = `${DEDICATED_SERVERS_API_ENDPOINT}/listing`;

export const CLOUD_SERVERS_API_ENDPOINT = `${BASE_API_ENDPOINT}/shop/catalog/cloud-server`;
export const CLOUD_SERVERS_LISTING_API_ENDPOINT = `${CLOUD_SERVERS_API_ENDPOINT}/listing`;

export const DOMAINS_API_ENDPOINT = `${BASE_API_ENDPOINT}/shop/catalog/domain`;
export const DOMAIN_AVAILABILITY_ENDPOINT = `${DOMAINS_API_ENDPOINT}/check`;
export const DOMAINS_LISTING_API_ENDPOINT = `${DOMAINS_API_ENDPOINT}/top-level-domain/listing`;
export const DOMAINS_LISTING_BY_GROUP_API_ENDPOINT = `${DOMAINS_API_ENDPOINT}/top-level-domain/by-group`;
export const DOMAINS_WHOIS_API_ENDPOINT = `${DOMAINS_API_ENDPOINT}/who-is`;
export const DOMAINS_SUGGESTIONS_ENDPOINT = `${DOMAINS_API_ENDPOINT}/tld/suggestions`;

export const CUSTOMER_DOMAINS_API_ENDPOINT = `${BASE_API_ENDPOINT}/domains`;
export const CUSTOMER_DOMAINS_WITHOUT_HOSTING_API_ENDPOINT = `${BASE_API_ENDPOINT}/domains/without-hosting/all`;
export const CUSTOMER_PRODUCTS_API_ENDPOINT = `${BASE_API_ENDPOINT}/hostings`;
export const CUSTOMER_SERVERS_API_ENDPOINT = `${BASE_API_ENDPOINT}/servers`;
export const CUSTOMER_EMAILS_API_ENDPOINT = `${BASE_API_ENDPOINT}/emails`;
export const CUSTOMER_EMAILS_EMAIL_API_ENDPOINT = `${BASE_API_ENDPOINT}/emails/%(id)s`;
export const CUSTOMER_CONTACTS_API_ENDPOINT = `${BASE_API_ENDPOINT}/contacts`;
export const CUSTOMER_API_ENDPOINT = `${BASE_API_ENDPOINT}/customer`;

export const CUSTOMER_PRODUCTS_FILTER_STATUS_COLLECTION_API_ENDPOINT = `${CUSTOMER_PRODUCTS_API_ENDPOINT}/filter/statuses`;
export const CUSTOMER_PRODUCTS_FILTER_PRODUCTS_COLLECTION_API_ENDPOINT = `${CUSTOMER_PRODUCTS_API_ENDPOINT}/filter/products`;
export const CUSTOMER_PRODUCTS_PRODUCT_SERVICES_API_ENDPOINT = `${CUSTOMER_PRODUCTS_API_ENDPOINT}/%(id)s/services/customer`;
export const CUSTOMER_PRODUCTS_PRODUCT_HOSTING_BACKUP_API_ENDPOINT = `${CUSTOMER_PRODUCTS_API_ENDPOINT}/%(id)s/services/backup/available-backups`;
export const CUSTOMER_PRODUCTS_PRODUCT_HOSTING_BACKUP_HISTORY_API_ENDPOINT = `${CUSTOMER_PRODUCTS_API_ENDPOINT}/%(id)s/services/backup/restore-history`;
export const CUSTOMER_PRODUCTS_PRODUCT_HOSTING_BACKUP_RESTORE_API_ENDPOINT = `${CUSTOMER_PRODUCTS_API_ENDPOINT}/%(id)s/services/backup/restore`;
export const CUSTOMER_PRODUCTS_PRODUCT_SERVICE_CANCELLATION_API_ENDPOINT = `${CUSTOMER_PRODUCTS_API_ENDPOINT}/%(product.id)s/services/%(service.id)s/cancel`;
export const CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_DIRECT_LOGIN_LINK_API_ENDPOINT = `${CUSTOMER_PRODUCTS_API_ENDPOINT}/%(id)s/cpanel-direct-login-link`;
export const CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_CHANGE_PASSWORD_API_ENDPOINT = `${CUSTOMER_PRODUCTS_API_ENDPOINT}/%(id)s/change-cpanel-password`;

export const CUSTOMER_SERVERS_FILTER_STATUS_COLLECTION_API_ENDPOINT = `${CUSTOMER_SERVERS_API_ENDPOINT}/filter/statuses`;
export const CUSTOMER_SERVERS_FILTER_PRODUCTS_COLLECTION_API_ENDPOINT = `${CUSTOMER_SERVERS_API_ENDPOINT}/filter/products`;
export const CUSTOMER_PRODUCTS_SERVER_SERVICES_API_ENDPOINT = `${CUSTOMER_SERVERS_API_ENDPOINT}/%(id)s/services/customer`;

export const CUSTOMER_DOMAINS_CREATE_OPERATION = `${CUSTOMER_DOMAINS_API_ENDPOINT}/mk/create-operation-reguest`;
export const CUSTOMER_DOMAINS_UPDATE_OPERATION = `${CUSTOMER_DOMAINS_API_ENDPOINT}/mk/update-operation-reguest`;

export const CUSTOMER_DOMAINS_DOMAIN_CANCEL_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/request-cancellation`;
export const CUSTOMER_DOMAINS_DOMAIN_AUTORENEW_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/request-autorenew`;
export const CUSTOMER_DOMAINS_DOMAIN_REQUEST_RENEWAL_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/request-renewal`;
export const CUSTOMER_DOMAINS_DOMAIN_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s`;
export const CUSTOMER_DOMAINS_DOMAIN_BY_NAME_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(name)s/by-name`;
export const CUSTOMER_DOMAINS_DOMAIN_UNLOCK_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/unlock`;
export const CUSTOMER_DOMAINS_DOMAIN_LOCK_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/lock`;
export const CUSTOMER_DOMAINS_DOMAIN_LOCKED_STATUS_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/is-locked`;
export const CUSTOMER_DOMAINS_DOMAIN_TRANSFER_IN_API_ENDPOINT = `${BASE_API_ENDPOINT}/shop/domains/transfer-in`;
export const CUSTOMER_DOMAINS_DOMAIN_SERVICES_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/services/customer`;
export const CUSTOMER_DOMAINS_DOMAIN_SERVICE_CANCELLATION_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(domain.id)s/services/%(service.id)s/cancel`;
export const CUSTOMER_DOMAINS_DOMAIN_GET_EPP_TRANSFER_CODE_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/request-epp-transfer-code`;
export const CUSTOMER_DOMAINS_DOMAIN_NAMESERVERS_UPDATE = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/update-name-servers`;
export const CUSTOMER_DOMAINS_DOMAIN_NAME_SERVERS_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/name-servers`;
export const CUSTOMER_DOMAINS_DOMAIN_REGISTER_CONTACTS_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/register-contacts`;
export const CUSTOMER_DOMAINS_FILTER_STATUS_COLLECTION_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/filter/statuses`;
export const CUSTOMER_DOMAINS_FILTER_EXTENSIONS_COLLECTION_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/filter/extensions`;
export const CUSTOMER_DOMAINS_FILTER_REGISTER_CONTACTS_COLLECTION_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/filter/domain-registrant-contacts`;
export const CUSTOMER_DOMAINS_DOMAIN_ZONE_FILE_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/dns-zone`;
export const CUSTOMER_DOMAINS_CREATE_DOMAIN_ZONE_FILE_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/dns-zone/add`;
export const CUSTOMER_DOMAINS_DELETE_DOMAIN_ZONE_FILE_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/dns-zone/delete`;
export const CUSTOMER_DOMAINS_RESET_DOMAIN_ZONE_FILE_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/dns-zone/reset`;
export const CUSTOMER_DOMAINS_DOMAIN_ZONE_ADD_RECORD_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/dns-zone-record/add`;
export const CUSTOMER_DOMAINS_DOMAIN_ZONE_UPDATE_RECORD_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/dns-zone-record/update`;
export const CUSTOMER_DOMAINS_DOMAIN_ZONE_DELETE_RECORD_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/dns-zone-record/delete`;
export const CUSTOMER_DOMAINS_DOMAIN_REDIRECT_AVAILABLE_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/check-redirect`;
export const CUSTOMER_DOMAINS_DOMAIN_GET_REDIRECTION_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/redirect`;
export const CUSTOMER_DOMAINS_DOMAIN_REDIRECTION_UPDATE_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/redirect/update`;
export const CUSTOMER_DOMAINS_DOMAIN_REDIRECTION_DELETE_API_ENDPOINT = `${CUSTOMER_DOMAINS_API_ENDPOINT}/%(id)s/redirect/delete`;
export const CUSTOMER_DOMAINS_DOMAIN_BUY_ID_PROTECTION = `${BASE_API_ENDPOINT}/shop/domain/buy-id-protection`;

export const CUSTOMER_CONTACTS_CHANGE_DOMAIN_CONTACT = `${CUSTOMER_CONTACTS_API_ENDPOINT}/change-%(type)s-contact-for-domain`;
export const CUSTOMER_CONTACTS_ASSIGN_PRODUCT_CONTACT = `${CUSTOMER_CONTACTS_API_ENDPOINT}/assign-%(type)s-contact-to-product`;
export const CUSTOMER_CONTACTS_CONTACT_DETAILS = `${CUSTOMER_CONTACTS_API_ENDPOINT}/%(id)s`;
export const CUSTOMER_CONTACTS_UNASSIGN_PRODUCT_FROM_CONTACT_API_ENDPOINT = `${CUSTOMER_CONTACTS_API_ENDPOINT}/dissociate-contact-from-product`;
export const CUSTOMER_CONTACTS_ACCEPT_INVITATION = `${CUSTOMER_CONTACTS_API_ENDPOINT}/assistant-accept-invitation/%(token)s`;
export const CUSTOMER_CONTACTS_REGISTER_ASSISTANT = `${CUSTOMER_CONTACTS_API_ENDPOINT}/assistant-register`;

export const CUSTOMER_PRODUCTS_PRODUCT_DETAILS = `${CUSTOMER_PRODUCTS_API_ENDPOINT}/%(id)s`;
export const CUSTOMER_PRODUCTS_SERVER_DETAILS = `${CUSTOMER_SERVERS_API_ENDPOINT}/%(id)s`;

export const CUSTOMER_REGISTER = `${CUSTOMER_API_ENDPOINT}/register`;
export const CUSTOMER_UPDATE_REGISTER = `${CUSTOMER_API_ENDPOINT}/update-details`;

export const CUSTOMER_PAYERS_FOR_FILTER_API_ENDPOINT = `${BASE_API_ENDPOINT}/invoices/filter/payers`;
export const CUSTOMER_PERSONAL_INFORMATION_API_ENDPOINT = `${BASE_API_ENDPOINT}/me`;
export const CUSTOMER_PERSONAL_INFORMATION_SIX_MONTH_CHECKUP_API_ENDPOINT=`${BASE_API_ENDPOINT}/me/verify`;
export const CUSTOMER_UPDATE_LANGUAGE_API_ENDPOINT = `${CUSTOMER_API_ENDPOINT}/change-language`;

export const PROINVOICES_API_ENDPOINT = `${BASE_API_ENDPOINT}/invoices`;
export const PROINVOICES_FILTER_STATUS_COLLECTION_API_ENDPOINT = `${BASE_API_ENDPOINT}/invoices/filter/statuses`;
export const PROINVOICES_DOWNLOAD_PROINVOICE_API_ENDPOINT = `${PROINVOICES_API_ENDPOINT}/%(id)s/download`;
export const PROINVOICES_APPLY_CREDIT_API_ENDPOINT = `${PROINVOICES_API_ENDPOINT}/%(id)s/apply-credit`;
export const PROINVOICES_FORWARD_PROINVOICE = `${PROINVOICES_API_ENDPOINT}/%(id)s/forward`;
export const PROINVOICES_UPDATE_PROINVOICE = `${PROINVOICES_API_ENDPOINT}/%(id)s/update`;
export const PROINVOICES_ASSIGN_PROINVOICE_PAYER = `${PROINVOICES_API_ENDPOINT}/assign-payer-for-invoice`;
export const PROINVOICES_MASS_PAY_ENDPOINT = `${PROINVOICES_API_ENDPOINT}/merge`;
export const PROINVOICES_CANCEL_PROINVOICE_API_ENDPOINT = `${PROINVOICES_API_ENDPOINT}/%(id)s/cancel`;

export const INVOICES_API_ENDPOINT = `${BASE_API_ENDPOINT}/receipts`;
export const INVOICES_FILTER_STATUS_COLLECTION_API_ENDPOINT = `${BASE_API_ENDPOINT}/receipts/filter/statuses`;
export const INVOICES_DOWNLOAD_INVOICE_API_ENDPOINT = `${INVOICES_API_ENDPOINT}/%(id)s/download`;
export const INVOICES_PREVIEW_INVOICE_API_ENDPOINT = `${INVOICES_API_ENDPOINT}/%(id)s/preview`;
export const INVOICES_APPLY_CREDIT_API_ENDPOINT = `${INVOICES_API_ENDPOINT}/%(id)s/apply-credit`;
export const INVOICES_FORWARD_INVOICE = `${INVOICES_API_ENDPOINT}/%(id)s/send/email`;
export const INVOICES_SEND_VIA_POST_INVOICE = `${INVOICES_API_ENDPOINT}/%(id)s/send/post`;
export const INVOICES_UPDATE_INVOICE = `${INVOICES_API_ENDPOINT}/%(id)s/update`;
export const INVOICES_ASSIGN_INVOICE_PAYER = `${INVOICES_API_ENDPOINT}/assign-payer-for-invoice`;
export const INVOICES_MASS_PAY_ENDPOINT = `${INVOICES_API_ENDPOINT}/merge`;
export const INVOICES_DIGITAL_INVOICES_SETTINGS_ENDPOINT = `${BASE_API_ENDPOINT}/digital/settings`;

export const PAYMENT_METHODS_API_ENDPOINT = `${BASE_API_ENDPOINT}/payment-methods`;

export const SEARCH_API_ENDPOINT = `${BASE_API_ENDPOINT}/search/general/%(keyword)s`;
export const SEARCH_CONTACTS_API_ENDPOINT = `${BASE_API_ENDPOINT}/search/contacts`;
export const SEARCH_PAYERS_API_ENDPOINT = `${BASE_API_ENDPOINT}/search/payers`;

export const CREDIT_API_ENDPOINT = `${CUSTOMER_API_ENDPOINT}/credit`;
export const CREDIT_PURCHASE_API_ENDPOINT = `${PROINVOICES_API_ENDPOINT}/generate-for-credit-purchase`;

export const HELPDESK_API_ENDPOINT = `${BASE_API_ENDPOINT}/helpdesk`;
export const HELPDESK_KNOWLEDGEBASE_API_ENDPOINT = `${BASE_API_ENDPOINT}/helpdesk/knowledge-base`;
export const HELPDESK_KNOWLEDGE_BASE_CATEGORIES_API_ENDPOINT = `${HELPDESK_API_ENDPOINT}/knowledge-base/categories`;
export const HELPDESK_KNOWLEDGE_BASE_MOST_POPULAR_ARTICLES_API_ENDPOINT = `${HELPDESK_API_ENDPOINT}/knowledge-base/most-popular`;
export const HELPDESK_KNOWLEDGE_BASE_ARTICLE_API_ENDPOINT = `${HELPDESK_API_ENDPOINT}/knowledge-base/articles/%(id)s`;
export const HELPDESK_KNOWLEDGE_BASE_ARTICLES_SEARCH_BY_KEYWORD_API_ENDPOINT = `${HELPDESK_API_ENDPOINT}/knowledge-base/articles/%(keyword)s/by-keyword`;
export const HELPDESK_KNOWLEDGEBASE_VOTE_ARTICLE_API_ENDPOINT = `${HELPDESK_KNOWLEDGE_BASE_ARTICLE_API_ENDPOINT}/vote`;
export const HELPDESK_TICKETS_LIST_API_ENDPOINT = `${HELPDESK_API_ENDPOINT}/tickets`;
export const HELPDESK_TICKETS_TICKET_DETAILS = `${HELPDESK_TICKETS_LIST_API_ENDPOINT}/%(id)s`;
export const HELPDESK_TICKETS_TICKET_FEEDBACK_CREATE_API_ENDPOINT = `${BASE_API_ENDPOINT}/ticket/%(id)s/feedback/create`;
export const SECURITY_API_ENDPOINT = `${BASE_API_ENDPOINT}/security`;
export const SECURITY_FETCH_SECURITY_QUESTIONS_API_ENDPOINT = `${SECURITY_API_ENDPOINT}/security-questions`;
export const SECURITY_UPDATE_SECURITY_QUESTION_API_ENDPOINT = `${SECURITY_API_ENDPOINT}/security-question`;
export const SECURITY_CHANGE_PASSWORD_API_ENDPOINT = `${SECURITY_API_ENDPOINT}/change-password`;

export const HELPDESK_TICKETS_API_ENDPOINT = `${BASE_API_ENDPOINT}/ticket`;
export const HELPDESK_TICKETS_DEPARTMENTS_API_ENDPOINT = `${BASE_API_ENDPOINT}/support-departments/listing`;
export const HELPDESK_TICKETS_RELATED_PRODUCTS_API_ENDPOINT = `${HELPDESK_TICKETS_API_ENDPOINT}/related-products`;

export const HELPDESK_TICKETS_CREATE_TICKET_API_ENDPOINT = `${HELPDESK_TICKETS_API_ENDPOINT}/create/customer`;
export const HELPDESK_TICKETS_REPLY_TO_TICKET_API_ENDPOINT = `${HELPDESK_TICKETS_API_ENDPOINT}/reply`;

export const FEEDBACK_API_ENDPOINT = `${BASE_API_ENDPOINT}/feedback`;
export const FEEDBACK_CREATE_API_ENDPOINT = `${FEEDBACK_API_ENDPOINT}/create`;

export const HELPDESK_TICKETS_TICKET_ATTACHMENT_BASE_ENDPOINT = `${BASE_API_ENDPOINT}`;

export const HELPDESK_TICKETS_TICKET_CLOSE = `${BASE_API_ENDPOINT}/tickets/%(id)s/close`;

export const SYSTEM_API_ENDPOINT = `${BASE_API_ENDPOINT}/system`;
export const SYSTEM_FETCH_PREFERENCES_API_ENDPOINT = `${SYSTEM_API_ENDPOINT}/preferences`;
export const ADDRESSING_COUNTRIES_API_ENDPOINT = `${BASE_API_ENDPOINT}/countries`;

export const APPS_API_ENDPOINT = `${BASE_API_ENDPOINT}/app`;
export const APPS_ALL_API_ENDPOINT = `${APPS_API_ENDPOINT}/clients`;
export const APPS_CREATE_API_ENDPOINT = `${APPS_API_ENDPOINT}/clients`;
export const APPS_UPDATE_API_ENDPOINT = `${APPS_API_ENDPOINT}/clients`;
export const APPS_DELETE_API_ENDPOINT = `${APPS_API_ENDPOINT}/clients`;
export const APPS_ALL_SCOPES_API_ENDPOINT = `${APPS_API_ENDPOINT}/scopes`;
export const APPS_APP_SCOPE_TOGGLE_API_ENDPOINT = `${APPS_API_ENDPOINT}/scopes/%(id)s/toggle`;

export const NOTIFICATIONS_API_ENDPOINT = `${BASE_API_ENDPOINT}/notifications`;
export const NOTIFICATIONS_UNREAD_API_ENDPOINT = `${NOTIFICATIONS_API_ENDPOINT}/unread`;
export const NOTIFICATIONS_MARK_AS_READ_API_ENDPOINT = `${NOTIFICATIONS_API_ENDPOINT}/mark-read`;
export const NOTIFICATIONS_CONFIG_API_ENDPOINT = `${BASE_API_ENDPOINT}/email/notification/config`;
export const NOTIFICATIONS_ENABLE_API_ENDPOINT = `${BASE_API_ENDPOINT}/customer/email/notification/config/update`;

export const WEBSITE_ENDPOINT = process.env.REACT_APP_MKHOST_WEBSITE_DOMAIN;

export const REFERRAL_GET_CODE = `${BASE_API_ENDPOINT}/referral/generate-code`;
export const REFERRAL_GET_INVOICES = `${BASE_API_ENDPOINT}/referral/invoices`;
export const REFERRAL_SEND_EMAIL = `${BASE_API_ENDPOINT}/referral/send-invitation`;

export const EMAIL_SEND_VALIDATION_LINK = `${BASE_API_ENDPOINT}/customer/email/verification-request`;
export const EMAIL_VERIFY = `${BASE_API_ENDPOINT}/customer/register/verify-email`;
