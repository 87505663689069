import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  MobileOnlyView,
  TabletView,
  BrowserView,
  isMobileOnly
} from "react-device-detect";
import withSizes from "react-sizes";
import isLaptop from "../../utils/isLaptopScreen";
import { Translate, withLocalize } from "react-localize-redux";
import styled from "styled-components";

import Breadcrumb from "../../components/Breadcrumb";
import BreadcrumbItem from "../../components/Breadcrumb/BreadcrumbItem";

import Modal from "../../components/Modal";
import ActionModal from "../../components/ActionModal";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import PrimaryWarningButton from "../../components/Buttons/PrimaryWarningButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";

import withLoading from "../../components/Loaders/WithLoading";
//import TableLoader from "../../components/Loaders/TableLoader";
import FilterLoader from "../../components/Loaders/FilterLoader";

import DomainsFilter from "./DomainsFilter";
import DomainsList from "./DomainsList";

import CancelDomain from "./CancelDomain";
import RenewDomainWithPeriod from "./RenewDomainWithPeriod";
import TransferInDomain from "./TransferInDomain";

import {
  fetchCustomerDomains,
  fetchCustomerDomainsFiltersStatusCollection,
  fetchCustomerDomainsFiltersExtensionsCollection,
  fetchCustomerDomainsFiltersRegisterContactsCollection,
  cancelDomain,
  autoRenewDomain,
  selectCustomerDomainsListTableRow,
  deselectCustomerDomainsListTableRow,
  selectAllCustomerDomainsListTableRows,
  deselectAllCustomerDomainsListTableRows,
  requestRenewDomain,
  requestRenewDomainWithPeriod
} from "../Customer/actions/customerActions";
import {
  getCustomerDomains,
  isFetchingDomains,
  isFetchingDomainFilters,
  getDomainsListFilters,
  isFetchingDomainStatusFilters,
  isFetchingDomainExtensionsFilters,
  isFetchingDomainRegisterContactsFilter,
  getCustomerCurrencyCode
} from "../Customer/reducers/customerReducer";

import displayToastMessageForResponse from "../../utils/displayToastMessageForResponse";

import {
  ROUTE_VIEW_DOMAIN_SETTINGS,
  ROUTE_SHOP_DOMAINS,
  ROUTE_BILLING_VIEW_PROINVOICE,
  ROUTE_CART,
  compileRoute
} from "../../routes/routes";

import DomainsEmptyState from "./DomainsEmptyState";
import parseDomain from "../../utils/parseDomain";
import { fetchDomains } from "./actions/domainsActions";
import { getDomainsPricing } from "./reducers/domainsReducer";

const StyledDomainsWrapper = styled.div`
  margin-bottom: ${props => props.isMobile && "100px"};
`;

const DomainFilterWithLoading = withLoading(DomainsFilter, FilterLoader);

class Domains extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDomain: null,
      showCancelDomainModal: false,
      cancelDomainPeriod: null,
      cancelDomainSubmitting: false,
      showAutoRenewDomainModal: false,
      autoRenewDomainSubmitting: false,
      renewDomainSubmitting: false,
      renewDomainSubmittingId: 0,
      showRenewNowDomain: false,
      showSelectRenewalPeriodDomainModal: false,
      domainRenewalPeriod: 1,
      showTransferDomainModal: false,
      submittingTransferDomain: false,
      invalidTransferDomain: false,
      transferDomainDetails: null
    };

    this.state = {
      searchQuery:
        props.domains && props.domains.filters
          ? props.domains.filters.keyword
          : ""
    };

    this.domainSettingsRoute = compileRoute(ROUTE_VIEW_DOMAIN_SETTINGS);
  }

  componentDidMount() {
    this.props.fetchDomains();

    this.props.fetchCustomerDomains(
      this.props.domains.paging.limit,
      1,
      this.props.domains.sort,
      this.props.domains.filters
    );

    this.props.fetchCustomerDomainsFiltersStatusCollection();
    this.props.fetchCustomerDomainsFiltersExtensionsCollection();
    this.props.fetchCustomerDomainsFiltersRegisterContactsCollection();
  }

  onShowRenewNowModal = domain => {
    this.setState({
      currentDomain: domain,
      showRenewNowModal: true
    });
  };

  onHideRenewNowModal = () => {
    this.setState({
      showRenewNowModal: false
    });
  };

  onRenewProduct = domain => {
    this.setState({
      renewDomainSubmitting: true,
      renewDomainSubmittingId: domain.id
    });
    this.props
      .requestRenewDomain(domain)
      .then(({ error, invoice, messages }) => {
        if (!error) {
          this.props.history.push(
            compileRoute(ROUTE_BILLING_VIEW_PROINVOICE)({ id: invoice.id })
          );
        }
        if (error) {
          this.onHideRenewNowModal();
          displayToastMessageForResponse(
            this.props.translate("domains.title"),
            { error, messages }
          );
          this.setState({
            renewDomainSubmitting: false,
            renewDomainSubmittingId: 0
          });
        }
      })
      .catch(error => {
        this.onHideRenewNowModal();
        displayToastMessageForResponse(this.props.translate("domains.title"), {
          error
        });
        this.setState({
          renewDomainSubmitting: false,
          renewDomainSubmittingId: 0
        });
      });
  };

  onShowCancelDomainModal = product => {
    this.setState({
      showCancelDomainModal: true,
      currentDomain: product,
      cancelDomainSubmitting: false
    });
  };

  onHideCancelDomainModal = () => {
    this.setState({
      showCancelDomainModal: false,
      currentDomain: null
    });
  };

  onChangeCancelDomainPeriod = period => {
    this.setState({
      cancelDomainPeriod: period
    });
  };

  onCancelDomain = () => {
    if (this.state.currentDomain) {
      this.setState({
        cancelDomainSubmitting: true
      });
      return this.props
        .cancelDomain(this.state.currentDomain, this.state.cancelDomainPeriod)
        .then(cancelled => {
          this.setState({
            cancelDomainSubmitting: true,
            cancelDomainPeriod: null
          });
          this.onHideCancelDomainModal();
          displayToastMessageForResponse(
            this.props.translate("general.domain"),
            cancelled
          );
        })
        .catch(error =>
          displayToastMessageForResponse(
            this.props.translate("general.domain"),
            error
          )
        )
        .finally(() => {
          this.setState({
            cancelDomainSubmitting: true,
            cancelDomainPeriod: null
          });
        });
    }
  };

  onChangeNameserversDomain = domain => {
    this.props.history.push(
      this.domainSettingsRoute({
        id: domain.id,
        name: domain.domain_name
      })
    );
  };

  onShowAutoRenewDomain = domain => {
    this.setState({
      currentDomain: domain,
      showAutoRenewDomainModal: true
    });
  };

  onAutoRenewDomain = domain => {
    if (this.state.currentDomain) {
      this.setState({
        autoRenewDomainSubmitting: true
      });
      return this.props
        .autoRenewDomain(this.state.currentDomain)
        .then(autorenew => {
          this.setState({
            autoRenewDomainSubmitting: false
          });
          this.onHideAutoRenewDomain();
          displayToastMessageForResponse(
            this.props.translate("general.domain"),
            autorenew
          );
        })
        .catch(error => {
          displayToastMessageForResponse(
            this.props.translate("general.domain"),
            error
          );
        })
        .finally(() => {
          this.setState({
            autoRenewDomainSubmitting: false
          });
        });
    }
  };

  onHideAutoRenewDomain = () => {
    this.setState({
      showAutoRenewDomainModal: false
    });
  };

  remapFilterStatusesCollection = (value, callback) => {
    const options = this.props.domainsListFilters.statuses.map(status => {
      return {
        label: (
          <Translate>
            {({ translate }) =>
              translate("domains.filter.filter-statuses." + status.code)
            }
          </Translate>
        ),
        value: status.code
      };
    });

    callback(options);
    return options;
  };

  remapFilterExtensionsCollection = (value, callback) => {
    const options = this.props.domainsListFilters.extensions.map(extension => {
      return { label: extension.name, value: extension.code };
    });

    callback(options);
    return options;
  };

  remapFilterRegisterContactsCollection = (value, callback) => {
    const options = this.props.domainsListFilters.registerContacts.map(
      contact => {
        return { label: contact.name, value: contact.code };
      }
    );

    callback(options);
    return options;
  };

  countFiltersApplied = () => {
    if (!this.props.domains || !this.props.domains.filters) return 0;
    return Object.entries(this.props.domains.filters).reduce(
      (accumulator, item) => {
        return item[0] !== "keyword" && item[1] !== ""
          ? ++accumulator
          : accumulator;
      },
      0
    );
  };

  onShowRenewalPeriodModal = domain => {
    this.setState({
      showSelectRenewalPeriodDomainModal: true,
      currentDomain: domain
    });
  };

  onHideRenewalPeriodModal = () => {
    this.setState({
      showSelectRenewalPeriodDomainModal: false
    });
  };

  onChangeRenewalPeriod = period => {
    this.setState({
      domainRenewalPeriod: period ? period.value : 1
    });
  };
  onRenewDomainWithPeriod = domain => {
    this.setState({
      renewDomainSubmitting: true,
      renewDomainSubmittingId: domain.id
    });
    this.props
      .requestRenewDomainWithPeriod(domain, this.state.domainRenewalPeriod)
      .then(({ error, invoice, messages }) => {
        if (!error) {
          this.props.history.push(
            compileRoute(ROUTE_BILLING_VIEW_PROINVOICE)({ id: invoice.id })
          );
        }
        if (error) {
          this.onHideRenewalPeriodModal();
          displayToastMessageForResponse(
            this.props.translate("domains.title"),
            { error, messages }
          );
          this.setState({
            renewDomainSubmitting: false,
            renewDomainSubmittingId: 0
          });
        }
      })
      .catch(error => {
        this.onHideRenewalPeriodModal();
        displayToastMessageForResponse(this.props.translate("domains.title"), {
          error
        });
        this.setState({
          renewDomainSubmitting: false,
          renewDomainSubmittingId: 0
        });
      });
  };

  onShowTransferDomain = () => {
    this.setState({
      showTransferDomainModal: true
    });
  };

  onHideTransferDomain = () => {
    this.setState({
      showTransferDomainModal: false
    });
  };

  render() {
    const filtersApplied = this.countFiltersApplied();

    return (
      <Translate>
        {({ translate }) => (
          <StyledDomainsWrapper isMobile={isMobileOnly} className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <Breadcrumb separator={true}>
                <BreadcrumbItem to="/" title={translate("breadcrumbs.home")} />
                <BreadcrumbItem title={translate("breadcrumbs.domains")} />
              </Breadcrumb>
              <MobileOnlyView>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <DomainFilterWithLoading
                      isLoading={
                        this.props.isFetchingDomainStatusFilters ||
                        this.props.isFetchingDomainExtensionsFilters ||
                        this.props.isFetchingDomainRegisterContactsFilter
                      }
                      filterStatusOptions={this.remapFilterStatusesCollection}
                      domains={this.props.domains}
                      domainsListFilters={this.props.domainsListFilters}
                      filterRegisterContactsOptions={
                        this.remapFilterRegisterContactsCollection
                      }
                      filterExtensionsOptions={
                        this.remapFilterExtensionsCollection
                      }
                      fetchCustomerDomains={this.props.fetchCustomerDomains}
                      countFiltersApplied={this.countFiltersApplied}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12">
                    {this.props.domains && (
                      <DomainsList
                        isLoading={this.props.isFetching}
                        isLaptop={this.props.isLaptop}
                        domains={this.props.domains}
                        onRenewProduct={this.onShowRenewalPeriodModal}
                        onCancelDomain={this.onShowCancelDomainModal}
                        onChangeNameserversDomain={
                          this.onChangeNameserversDomain
                        }
                        onAutoRenewDomain={this.onShowAutoRenewDomain}
                        domainsListFilters={this.props.domainsListFilters}
                        filterStatusOptions={this.remapFilterStatusesCollection}
                        filterRegisterContactsOptions={
                          this.remapFilterRegisterContactsCollection
                        }
                        filterExtensionsOptions={
                          this.remapFilterExtensionsCollection
                        }
                        fetchCustomerDomains={this.props.fetchCustomerDomains}
                        selectCustomerDomainsListTableRow={
                          this.props.selectCustomerDomainsListTableRow
                        }
                        deselectCustomerDomainsListTableRow={
                          this.props.deselectCustomerDomainsListTableRow
                        }
                        selectAllCustomerDomainsListTableRows={
                          this.props.selectAllCustomerDomainsListTableRows
                        }
                        deselectAllCustomerDomainsListTableRows={
                          this.props.deselectAllCustomerDomainsListTableRows
                        }
                        countFiltersApplied={this.countFiltersApplied}
                        searchQuery={this.state.searchQuery}
                        renewDomainSubmitting={this.state.renewDomainSubmitting}
                        renewDomainSubmittingId={
                          this.state.renewDomainSubmittingId
                        }
                        onShowTransferDomain={this.onShowTransferDomain}
                      />
                    )}
                  </div>
                </div>
              </MobileOnlyView>
              <TabletView>
                <div className="row">
                  <div className="col-4 col-sm-4 col-md-4">
                    <DomainFilterWithLoading
                      isLoading={
                        this.props.isFetchingDomainStatusFilters ||
                        this.props.isFetchingDomainExtensionsFilters ||
                        this.props.isFetchingDomainRegisterContactsFilter
                      }
                      filterStatusOptions={this.remapFilterStatusesCollection}
                      domains={this.props.domains}
                      domainsListFilters={this.props.domainsListFilters}
                      filterRegisterContactsOptions={
                        this.remapFilterRegisterContactsCollection
                      }
                      filterExtensionsOptions={
                        this.remapFilterExtensionsCollection
                      }
                      fetchCustomerDomains={this.props.fetchCustomerDomains}
                      countFiltersApplied={this.countFiltersApplied}
                    />
                  </div>
                  <div className="col-8 col-sm-8 col-md-8">
                    {this.props.domains && (
                      <DomainsList
                        isLoading={this.props.isFetching}
                        isLaptop={this.props.isLaptop}
                        domains={this.props.domains}
                        onRenewProduct={this.onShowRenewalPeriodModal}
                        onCancelDomain={this.onShowCancelDomainModal}
                        onChangeNameserversDomain={
                          this.onChangeNameserversDomain
                        }
                        onAutoRenewDomain={this.onShowAutoRenewDomain}
                        domainsListFilters={this.props.domainsListFilters}
                        filterStatusOptions={this.remapFilterStatusesCollection}
                        filterRegisterContactsOptions={
                          this.remapFilterRegisterContactsCollection
                        }
                        filterExtensionsOptions={
                          this.remapFilterExtensionsCollection
                        }
                        fetchCustomerDomains={this.props.fetchCustomerDomains}
                        selectCustomerDomainsListTableRow={
                          this.props.selectCustomerDomainsListTableRow
                        }
                        deselectCustomerDomainsListTableRow={
                          this.props.deselectCustomerDomainsListTableRow
                        }
                        selectAllCustomerDomainsListTableRows={
                          this.props.selectAllCustomerDomainsListTableRows
                        }
                        deselectAllCustomerDomainsListTableRows={
                          this.props.deselectAllCustomerDomainsListTableRows
                        }
                        countFiltersApplied={this.countFiltersApplied}
                        searchQuery={this.state.searchQuery}
                        renewDomainSubmitting={this.state.renewDomainSubmitting}
                        renewDomainSubmittingId={
                          this.state.renewDomainSubmittingId
                        }
                        onShowTransferDomain={this.onShowTransferDomain}
                      />
                    )}
                  </div>
                </div>
              </TabletView>
              <BrowserView>
                {this.props.isLaptop && (
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      {this.props.domains && (
                        <DomainsList
                          isLoading={this.props.isFetching}
                          isLaptop={this.props.isLaptop}
                          domains={this.props.domains}
                          onRenewProduct={this.onShowRenewalPeriodModal}
                          onCancelDomain={this.onShowCancelDomainModal}
                          onChangeNameserversDomain={
                            this.onChangeNameserversDomain
                          }
                          onAutoRenewDomain={this.onShowAutoRenewDomain}
                          domainsListFilters={this.props.domainsListFilters}
                          filterRegisterContactsOptions={
                            this.remapFilterRegisterContactsCollection
                          }
                          filterStatusOptions={
                            this.remapFilterStatusesCollection
                          }
                          filterExtensionsOptions={
                            this.remapFilterExtensionsCollection
                          }
                          fetchCustomerDomains={this.props.fetchCustomerDomains}
                          selectCustomerDomainsListTableRow={
                            this.props.selectCustomerDomainsListTableRow
                          }
                          deselectCustomerDomainsListTableRow={
                            this.props.deselectCustomerDomainsListTableRow
                          }
                          selectAllCustomerDomainsListTableRows={
                            this.props.selectAllCustomerDomainsListTableRows
                          }
                          deselectAllCustomerDomainsListTableRows={
                            this.props.deselectAllCustomerDomainsListTableRows
                          }
                          countFiltersApplied={this.countFiltersApplied}
                          searchQuery={this.state.searchQuery}
                          renewDomainSubmitting={
                            this.state.renewDomainSubmitting
                          }
                          renewDomainSubmittingId={
                            this.state.renewDomainSubmittingId
                          }
                          onShowTransferDomain={this.onShowTransferDomain}
                        />
                      )}
                    </div>
                  </div>
                )}
                {!this.props.isLaptop && (
                  <div className="row">
                    <div className="col-12 col-sm-3 col-md-3">
                      <DomainFilterWithLoading
                        isLoading={
                          this.props.isFetchingDomainStatusFilters ||
                          this.props.isFetchingDomainExtensionsFilters ||
                          this.props.isFetchingDomainRegisterContactsFilter
                        }
                        filterStatusOptions={this.remapFilterStatusesCollection}
                        domainsListFilters={this.props.domainsListFilters}
                        filterExtensionsOptions={
                          this.remapFilterExtensionsCollection
                        }
                        filterRegisterContactsOptions={
                          this.remapFilterRegisterContactsCollection
                        }
                        fetchCustomerDomains={this.props.fetchCustomerDomains}
                        domains={this.props.domains}
                        countFiltersApplied={this.countFiltersApplied}
                      />
                    </div>
                    <div className="col-12 col-sm-9 col-md-9">
                      {this.props.domains && (
                        <DomainsList
                          isLoading={this.props.isFetching}
                          isLaptop={this.props.isLaptop}
                          domains={this.props.domains}
                          onRenewProduct={this.onShowRenewalPeriodModal}
                          onCancelDomain={this.onShowCancelDomainModal}
                          onChangeNameserversDomain={
                            this.onChangeNameserversDomain
                          }
                          onAutoRenewDomain={this.onShowAutoRenewDomain}
                          domainsListFilters={this.props.domainsListFilters}
                          filterStatusOptions={
                            this.remapFilterStatusesCollection
                          }
                          filterExtensionsOptions={
                            this.remapFilterExtensionsCollection
                          }
                          filterRegisterContactsOptions={
                            this.remapFilterRegisterContactsCollection
                          }
                          fetchCustomerDomains={this.props.fetchCustomerDomains}
                          selectCustomerDomainsListTableRow={
                            this.props.selectCustomerDomainsListTableRow
                          }
                          deselectCustomerDomainsListTableRow={
                            this.props.deselectCustomerDomainsListTableRow
                          }
                          selectAllCustomerDomainsListTableRows={
                            this.props.selectAllCustomerDomainsListTableRows
                          }
                          deselectAllCustomerDomainsListTableRows={
                            this.props.deselectAllCustomerDomainsListTableRows
                          }
                          countFiltersApplied={this.countFiltersApplied}
                          searchQuery={this.state.searchQuery}
                          renewDomainSubmitting={
                            this.state.renewDomainSubmitting
                          }
                          renewDomainSubmittingId={
                            this.state.renewDomainSubmittingId
                          }
                          onShowTransferDomain={this.onShowTransferDomain}
                        />
                      )}
                    </div>
                  </div>
                )}
              </BrowserView>
            </div>
            {this.state.showCancelDomainModal && (
              <Modal
                title={translate("domains.table.disable-auto-renew-for-domain")}
                subTitle={this.state.currentDomain.domain_name}
                onCloseModal={this.onHideCancelDomainModal}
                currentDomain={this.state.currentDomain}
                body={() => {
                  return (
                    <div>
                      {this.state.currentDomain && (
                        <CancelDomain
                          domain={this.state.currentDomain}
                          cancelDomainPeriod={this.state.cancelDomainPeriod}
                          onChangeCancelDomainPeriod={
                            this.onChangeCancelDomainPeriod
                          }
                        />
                      )}
                    </div>
                  );
                }}
                footer={() => [
                  <SecondaryButton
                    key="dont-cancel-domain"
                    onClick={e => this.onHideCancelDomainModal()}
                  >
                    {translate("domains.table.dont-disable")}
                  </SecondaryButton>,
                  <PrimaryWarningButton
                    key="cancel-domain"
                    disabled={
                      this.state.cancelDomainPeriod === null ||
                      this.state.cancelDomainSubmitting
                    }
                    submitting={this.state.cancelDomainSubmitting}
                    onClick={e => this.onCancelDomain()}
                  >
                    {translate("domains.table.disable-auto-renew-for-domain")}
                  </PrimaryWarningButton>
                ]}
              />
            )}

            {this.state.showAutoRenewDomainModal && (
              <Modal
                title={translate("domains.table.enable-auto-renew-for-domain")}
                subTitle={this.state.currentDomain.domain_name}
                onCloseModal={this.onHideAutoRenewDomain}
                currentDomain={this.state.currentDomain}
                body={() => {
                  return (
                    <div>
                      {this.state.currentDomain && (
                        <p>
                          {translate(
                            "domains.table.do-you-want-to-enable-auto-renew-for"
                          )}{" "}
                          {this.state.currentDomain.domain_name}?
                        </p>
                      )}
                    </div>
                  );
                }}
                footer={() => [
                  <SecondaryButton
                    key="dont-autorenew-domain"
                    onClick={e => this.onHideAutoRenewDomain()}
                  >
                    {translate("domains.table.cancel")}
                  </SecondaryButton>,
                  <PrimaryButton
                    key="autorenew-domain"
                    disabled={this.state.autoRenewDomainSubmitting}
                    submitting={this.state.autoRenewDomainSubmitting}
                    onClick={e =>
                      this.onAutoRenewDomain(this.state.currentDomain)
                    }
                  >
                    {translate("domains.table.enable-auto-renew")}
                  </PrimaryButton>
                ]}
              />
            )}

            {this.state.showRenewNowModal && (
              <ActionModal
                title={`${translate(
                  "domain.general-info.renew-domain-confirmation-modal-title"
                ).replace(
                  "{DOMAIN_NAME}",
                  this.state.currentDomain.domain_name
                )}`}
                onCloseActionModal={this.onHideRenewNowModal}
                body={() => (
                  <div>
                    {translate(
                      "domain.general-info.renew-domain-confirmation-modal-description"
                    )}
                  </div>
                )}
                footer={() => (
                  <React.Fragment>
                    <SecondaryButton onClick={() => this.onHideRenewNowModal()}>
                      {translate("domain.general-info.cancel")}
                    </SecondaryButton>
                    <PrimaryButton
                      disabled={this.state.renewDomainSubmitting}
                      submitting={this.state.renewDomainSubmitting}
                      onClick={() =>
                        this.onRenewProduct(this.state.currentDomain)
                      }
                    >
                      {translate("domain.general-info.renew-domain")}
                    </PrimaryButton>
                  </React.Fragment>
                )}
              />
            )}

            {this.state.showSelectRenewalPeriodDomainModal && (
              <ActionModal
                title={translate("domains.renewal-period")}
                onCloseActionModal={this.onHideRenewalPeriodModal}
                body={() => (
                  <RenewDomainWithPeriod
                    domain={this.state.currentDomain}
                    onChange={this.onChangeRenewalPeriod}
                    domainPrices={this.props.domainPricing}
                    parsedDomain={parseDomain(
                      this.state.currentDomain.domain_name
                    )}
                    customerCurrencyCode={this.props.customerCurrencyCode}
                  />
                )}
                footer={() => (
                  <React.Fragment>
                    <SecondaryButton
                      onClick={() => this.onHideRenewalPeriodModal()}
                    >
                      {translate("domain.general-info.cancel")}
                    </SecondaryButton>
                    <PrimaryButton
                      disabled={this.state.renewDomainSubmitting}
                      submitting={this.state.renewDomainSubmitting}
                      onClick={() =>
                        this.onRenewDomainWithPeriod(this.state.currentDomain)
                      }
                    >
                      {translate("domain.general-info.renew-domain")}
                    </PrimaryButton>
                  </React.Fragment>
                )}
              />
            )}

            {this.state.showTransferDomainModal && (
              <Modal
                title={translate("domain.transfer-in-domain.title")}
                subTitle={translate("domain.transfer-in-domain.description")}
                onCloseModal={this.onHideTransferDomain}
                body={() => (
                  <TransferInDomain
                    onHideTransferDomain={this.onHideTransferDomain}
                  />
                )}
              />
            )}
          </StyledDomainsWrapper>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    domains: getCustomerDomains(state),
    domainsListFilters: getDomainsListFilters(state),
    isFetching: isFetchingDomains(state),
    isFetchingDomainFilters: isFetchingDomainFilters(state),
    isFetchingDomainStatusFilters: isFetchingDomainStatusFilters(state),
    isFetchingDomainExtensionsFilters: isFetchingDomainExtensionsFilters(state),
    isFetchingDomainRegisterContactsFilter: isFetchingDomainRegisterContactsFilter(
      state
    ),
    customerCurrencyCode: getCustomerCurrencyCode(state),
    domainPricing: getDomainsPricing(state)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchCustomerDomains,
      cancelDomain,
      autoRenewDomain,
      fetchCustomerDomainsFiltersStatusCollection,
      fetchCustomerDomainsFiltersExtensionsCollection,
      fetchCustomerDomainsFiltersRegisterContactsCollection,
      selectCustomerDomainsListTableRow,
      deselectCustomerDomainsListTableRow,
      selectAllCustomerDomainsListTableRows,
      deselectAllCustomerDomainsListTableRows,
      requestRenewDomain,
      requestRenewDomainWithPeriod,
      fetchDomains
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(isLaptop)(withLocalize(Domains)));
