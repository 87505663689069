import React, { useEffect, useState } from "react";
import { MobileOnlyView, TabletView, BrowserView } from "react-device-detect";

import AccountPassword from "../../../components/AccountPassword";
import SecurityQuestion from "../../../components/SecurityQuestion";
import TwoStepAuthentication from "../../../components/TwoStepAuthentication";

// import { CHECK_STATUS_2FA_ENPOINT } from "../../../services/endpoints";
import axios from 'axios';

const Security = ({
  validateChangePasswordSubmit,
  onChangePassword,
  isChangingPassword,
 
}) => {


  return (
    <div className="row">
      <MobileOnlyView renderWithFragment>
        <div className="col-12 col-sm-12 col-md-12">
          <AccountPassword
            validateChangePasswordSubmit={validateChangePasswordSubmit}
            onChangePassword={onChangePassword}
            isChangingPassword={isChangingPassword}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-12">
          <TwoStepAuthentication
         
          />
        </div>
      </MobileOnlyView>
      <TabletView renderWithFragment>
        <div className="col-6 col-sm-6 col-md-6">
          <AccountPassword
            validateChangePasswordSubmit={validateChangePasswordSubmit}
            onChangePassword={onChangePassword}
            isChangingPassword={isChangingPassword}
          />
        </div>
        <div className="col-6 col-sm-6 col-md-6">
          <TwoStepAuthentication
           
          />
        </div>
      </TabletView>
      <BrowserView renderWithFragment>
        <div className="col-6 col-sm-6 col-md-6">
          <AccountPassword
            validateChangePasswordSubmit={validateChangePasswordSubmit}
            onChangePassword={onChangePassword}
            isChangingPassword={isChangingPassword}
          />
        </div>
        <div className="col-6 col-sm-6 col-md-6">
          <TwoStepAuthentication
           
          />
        </div>
      </BrowserView>
    </div>
  );
};

export default Security;