import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";
import { theme } from "../../../design/Themes";

import SectionTitle from "../../../components/SectionTitle";

import Tabs from "../../../components/Tabs";
import Tab from "../../../components/Tabs/Tab";
import TabContent from "../../../components/Tabs/TabContent";

import withLoading from "../../../components/Loaders/WithLoading";
import ContentLoader from "../../../components/Loaders/ContentLoader";
import ExternalLink from "../../../components/ExternalLink";

import DomainPriceTableContainer from "./DomainPriceTableContainer";

const StyledDomainPricesWrapper = styled.div`
  margin-bottom: 50px;
`;

const StyledDomainPrices = styled.div`
  margin-top: 100px;
  padding-bottom: 50px;
  .nav-tabs {
    border-bottom: none;
    @media (min-width: 768px) {
      .nav-item:last-child {
        right: 0;
        position: absolute;
      }
    }
    @media (max-width: 767px) {
      .nav-link {
        font-size: 12px;
        margin-left: 7px;
        margin-right: 7px;
      }
    }
    @media (max-width: 576px) {
      .nav-item {
        display: block;
        width: 100%;
      }
    }
  }

  & .nav-link-marnet {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.neutral5};
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 15px;
    margin-right: 15px;
    border: 0;
    text-transform: uppercase;
    border-bottom: 2px solid transparent;
  }

  & .nav-link-marnet:hover {
    border-bottom: 2px solid ${theme.neutral3};
    text-decoration: none;
  }
  & .nav-link-marnet.active {
    color: ${theme.neutralBase};
    background-color: transparent;
  }
`;

const DomainPricesWithLoading = withLoading(StyledDomainPrices, ContentLoader);

const DomainPrices = props => (
  <Translate>
    {({ translate, activeLanguage }) => {
      return (
        <StyledDomainPricesWrapper className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <DomainPricesWithLoading
              isLoading={props.isFetchingTopLevelGroupedDomains}
            >
              <SectionTitle>
                {translate("shop.domains.domain-prices.title")}
              </SectionTitle>
              <Tabs
                defaultTab="macedonian"
                withoutRouter={true}
                renderTabs={(active, onSelect) => {
                  let tabs = [];

                  for (const key in props.topLeveldomainsListingByGroup) {
                    if (
                      props.topLeveldomainsListingByGroup.hasOwnProperty(key)
                    ) {
                      tabs.push(
                        <Tab
                          key={`tab-${key}`}
                          label={key}
                          active={active}
                          onSelect={onSelect}
                        >
                          {translate("shop.domains.domain-prices." + key)}
                        </Tab>
                      );
                    }
                  }

                  return tabs.concat([
                    <li key="marnet-link" className="nav-item">
                      <ExternalLink
                        className="nav-link-marnet"
                        href="http://marnet.mk/doc/pravilnik-mk-mkd.pdf"
                      >
                        {translate("shop.domains.marnet-info")}
                      </ExternalLink>
                    </li>
                  ]);
                }}
                renderTabContent={active => {
                  for (const key in props.topLeveldomainsListingByGroup) {
                    if (
                      props.topLeveldomainsListingByGroup.hasOwnProperty(key) &&
                      active === key
                    ) {
                      return (
                        <TabContent
                          key={`tab-content-${key}`}
                          label={key}
                          active={active}
                        >
                          <DomainPriceTableContainer
                            tableItems={
                              props.topLeveldomainsListingByGroup[key]
                            }
                            customerCurrencyCode={
                              activeLanguage && props.customerCurrencyCode
                                ? props.customerCurrencyCode
                                : activeLanguage.code === "mk"
                                ? "мкд"
                                : "eur"
                            }
                          />
                        </TabContent>
                      );
                    }
                  }
                }}
              />
            </DomainPricesWithLoading>
          </div>
        </StyledDomainPricesWrapper>
      );
    }}
  </Translate>
);

export default DomainPrices;
